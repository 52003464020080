import { Link } from "@remix-run/react";
import { styled } from "styled-components";

export const ImageWrapper = styled(Link)`
  display: block;
  margin-bottom: ${({ theme }) => theme.space[8]};

  ${({ theme }) => theme.breakpoints.up("desktop")} {
    margin-bottom: ${({ theme }) => theme.space[16]};
  }
`;

export const TextWrapper = styled.div`
  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.breakpoints.up("desktop")} {
    align-items: flex-start;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
`;

export const CTA = styled(Link)`
  flex: 0 0 auto;
`;
