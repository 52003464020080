import FeaturedProduct from "~/components/FeaturedProduct";
import { StyledHomePage } from "./HomePage.styles";
import { FeaturedProduct as IFeaturedProduct } from "~/@types/shopify";

interface HomePageProps {
  product: null | IFeaturedProduct;
}

const HomePage = ({ product }: HomePageProps) => (
  <StyledHomePage>
    {product && (
      <FeaturedProduct
        cta={product.cta?.value}
        description={product.description?.value}
        handle={product.handle}
        isPartOfCollection={
          product.isPartOfCollection?.value === "true" || false
        }
        imageDesktop={product.imageDesktop?.reference?.image}
        imageMobile={
          product.imageMobile?.reference?.image ??
          product.imageDesktop?.reference?.image
        }
        title={product.title}
      />
    )}
  </StyledHomePage>
);

export default HomePage;
