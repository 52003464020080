import { CTA, ImageWrapper, TextWrapper } from "./FeaturedProduct.styles";
import Heading from "~/components/Heading";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ROUTES } from "~/routes/const";
import { Maybe } from "@shopify/hydrogen/dist/esnext/storefront-api-types";
import { useTheme } from "styled-components";
import { useMediaQuery } from "styled-breakpoints/use-media-query";

export interface FeaturedProductProps {
  cta?: string;
  description?: string;
  handle: string;
  imageDesktop: {
    altText?: Maybe<string>;
    url: string;
  };
  imageMobile: {
    altText?: Maybe<string>;
    url: string;
  };
  isPartOfCollection: boolean;
  title: string;
}

const FeaturedProduct = ({
  cta,
  description,
  handle,
  imageDesktop,
  imageMobile,
  isPartOfCollection,
  title,
}: FeaturedProductProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("desktop"));
  const route = isPartOfCollection ? ROUTES.SHOP : `${ROUTES.SHOP}/${handle}`;

  return (
    <>
      <ImageWrapper to={route}>
        {isDesktop ? (
          <LazyLoadImage
            alt={imageDesktop?.altText || title}
            src={imageDesktop?.url}
            visibleByDefault
          />
        ) : (
          <LazyLoadImage
            alt={imageMobile?.altText || title}
            src={imageMobile?.url}
            visibleByDefault
          />
        )}
      </ImageWrapper>
      <TextWrapper>
        {description && (
          <Heading
            hasMotion={false}
            size={isDesktop ? "h5" : "h6"}
            style={{
              marginBottom: theme.space[8],
              ...(isDesktop && {
                marginBottom: 0,
                marginRight: theme.space[128],
              }),
            }}
          >
            {description}
          </Heading>
        )}
        <CTA to={route}>
          <Heading as="span" size="h4">
            {`${cta || t("Own it")} >`}
          </Heading>
        </CTA>
      </TextWrapper>
    </>
  );
};

export default FeaturedProduct;
