import HomePage from "~/pages/HomePage/HomePage";
import { LoaderFunction, MetaFunction } from "@remix-run/node";
import { getFeaturedProduct } from "~/shopify";
import { useLoaderData } from "@remix-run/react";
import { BASE_PAGE_TITLE } from "./const";
import { FeaturedProduct } from "~/@types/shopify";
import { defaultCountryCode } from "~/i18n-config";

export const meta: MetaFunction = () => ({
  title: `Home - ${BASE_PAGE_TITLE}`,
  "twitter:title": `Home - ${BASE_PAGE_TITLE}`,
});

interface LoaderData {
  product: FeaturedProduct;
}

export const loader: LoaderFunction = async ({
  context,
}): Promise<LoaderData> => {
  const country = (context.country || defaultCountryCode) as string;
  const product = await getFeaturedProduct(country);

  return {
    product,
  };
};

const Home = () => {
  const { product } = useLoaderData<LoaderData>();

  return <HomePage product={product} />;
};

export default Home;
